/*
Theme Name: Sami
Theme URI: www.directedbysami.com
Author: hipcool studio
Author URI: www.hipcoolstudio.com
Version: 1.0

*/


/*-------------------------------------------------------------
    IMPORT OF GLOBAL CSS  / RESET AND FOUNDATION CSS DEFAULT
-------------------------------------------------------------*/

@font-face {
    font-family: 'Samsung Sharp Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Samsung Sharp Sans Bold'), url('css/samsungsharpsans-bold.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300&display=swap');
@import url("css/foundation.css");
@import url(css/normalized.css);
/*-------------------------------------------------------------
			DEFAULT EDIT CLASS
-------------------------------------------------------------*/

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

*::-webkit-scrollbar {
    display: none;
}

.clear {
    clear: both;
}

.noRight {
    padding-right: 0 !important;
}

.noLeft {
    padding-left: 0 !important;
}

body {
    background-color: #f7f7f7;
    font-family: 'Roboto', 'Samsung Sharp Sans Bold', 'Barlow', sans-serif;
    -webkit-font-smoothing: antialiased;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    margin: auto;
    min-height: 100vh;
    background: transparent linear-gradient(129deg, rgba(244, 252, 255, 0.49) 0%, rgba(224, 247, 255, 0.49) 100%) 0% 0% no-repeat padding-box;
}

body:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    /* background: url('./images/bgSky.jpg') no-repeat center center; */
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

html {
    scroll-behavior: smooth !important;
}
html,
body {
    background-repeat: no-repeat;
    margin: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'roboto', sans-serif;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.footer {
    text-align: center;
    cursor: pointer;
}

.row {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 62.5em;
}

.work-container-header {
    position: initial;
    display: flex;
    width: 84.8%;
    height: 6em;
    right: 0;
}

.work-container-footer {
    width: 82%;
    position: fixed;
    bottom: 0;
    height: 6em;
    /* right: ; */
    text-align: center;
    margin: auto;
}
.work-watch-film {
    width: 140px;
    margin: 1.5em auto auto;
    cursor: pointer;
    transition: all 0.5s ease-out 0s;
}
.work-watch-film span {
    float: left;
    margin: 1em 0.5em 0 0em;
}
.work-watch-film svg {
    float: left;
}
.work-watch-film:hover{
    opacity: 0.8;
}
.work-title {
    position: absolute;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    font-size: 8rem;
    -webkit-text-stroke: unset;
    -webkit-text-fill-color: #fff;
    font-family: Samsung Sharp Sans Bold;
    top: 0%;
    mix-blend-mode: difference;
    transform: translateX(-50%);
    left: 50%;
}

.nostyle.work-back-button {
    font-weight: bold;
    font-size: 1em;
}

.work-back-button {
    position: relative;
    flex-grow: 10;
    margin-top: 4em;
    font-family: 'roboto', 'Samsung Sharp Sans Bold', sans-serif;
    cursor: pointer !important;
}

.work-back-button svg {}

.work-back-button span {
    margin: 0 0.5em;
    font-size: 1em;
}

.work-container-gallery {
    height: calc(100% - 12em);
    white-space: nowrap;
    overflow-x: scroll;
    top: 6em;
    width: 80%;
    position: fixed;
}

.work-container-gallery img {
    display: inline;
    margin: 0px 0em;
    padding: 0px;
    vertical-align: middle;
    height: 100%;
}


/*-------------------------------------------------------------
			HEADER WORK IN PROGRESS
-------------------------------------------------------------*/

.center {
    text-align: center;
    margin: auto;
    padding: 2em 0;
}

.center h1 {
    font-size: 2em;
    font-weight: 800;
    position: relative;
    margin: 1em 0 0.5em 0;
    display: inline-block;
}

.center p {
    margin: 1.2em 0 0;
}

.center p a {
    color: #000;
    transition: all 0.5s ease-out 0s;
}

.center p a:hover {
    color: #5b5b5b;
}

.frameFilm {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
}

.logo h1 {
    margin: 0;
}

.videoModal {
    width: 100vw;
    height: 100vh;
    background-color: #000000e3;
    z-index: 101;
    position: absolute;
}

.hidden {
    display: none;
}

.visible {
    display: unset;
}

.video-modal-class {
    margin: auto;
    width: 100% !important;
    height: 80vh !important;
}

.exit {
    text-align: left;
    color: #fff;
    margin: 1em auto;
    left: 15%;
    position: relative;
    font-weight: 600;
    font-size: 1em;
    transition: all 0.5s ease-out 0s;
}

.exit svg {
    margin: 0 0.5em;
}

.exit:hover {
    opacity: 0.6;
    cursor: pointer;
}


/*--------------------------------------------------------
		ANIMATIONS LANDING WORK IN PROGRESS
-------------------------------------------------------------*/

.showUP,
.showUPTwo,
.showUPThree {
    animation: showUP 1s ease-out forwards;
    animation-iteration-count: 1;
    -webkit-animation: showUP 1s;
    animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    position: relative;
}

@keyframes showUP {
    from {
        top: 10px;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}

.showUPTwo {
    animation: showUPTwo 2s ease-out forwards;
    -webkit-animation: showUPTwo 2s;
}

@keyframes showUPTwo {
    from {
        top: 20px;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}

.showUPThree {
    animation: showUPThree 1s ease-out forwards;
    -webkit-animation: showUPThree 1s;
}

@keyframes showUPThree {
    from {
        top: 20px;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}


/*--------------------------------------------------------
		GENERAL RULES
-------------------------------------------------------------*/

.sideSx {
    margin: 1em 2em;
    position: fixed;
}

.lateral {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    position: fixed;
}

.lateral ul {
    padding: 0;
    margin: 0;
}

.lateral li {
    list-style: none;
    /* margin: 10em -1.4em; */
    margin: 10em -1.4em;
    transform: rotate(-90deg) translateY(-5em);
}

.lateral li.works.shown {
    transform: rotate(-90deg) translateY(0);
    transition: 375ms transform 0.750s ease-out;
}

.lateral li.about.shown {
    transform: rotate(-90deg) translateY(0);
    transition: 750ms transform 0.750s ease-out;
}

.lateral li.contacts.shown {
    transform: rotate(-90deg) translateY(0);
    transition: 1125ms transform 0.750s ease-out;
}

.lateral li a {
    font-size: 1.3em;
    color: #000;
    font-weight: 600;
    text-decoration: none;
}

.logo {
    opacity: 0;
    position: absolute;
    animation: slideDown 0.7s ease-out forwards;
}

@-webkit-keyframes slideDown {
    0% {
        -webkit-transform: translateY(-2em);
        transform: translateY(-2em);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        -webkit-transform: translateY(-2em);
        transform: translateY(-2em);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.logo h1,
.logoOutline h1 {
    font-family: 'roboto', sans-serif;
    font-size: 4em;
    font-weight: 800;
}

.logo h1 a:link,
.logo h1 a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

.logoOutline h1 {
    color: transparent;
    -webkit-text-stroke-width: 0.01em;
    -webkit-text-stroke-color: black;
}

.videoScroll {
    display: none;
}

.intro {
    text-align: center;
    position: relative;
    left: 0;
    right: 50%;
    transform: translateY(-50%);
    top: 50%;
}

.scrolldown {
    font-family: 'Barlow Condensed', sans-serif;
    text-align: center;
    top: 81%;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
}

.scrolldown span {
    display: block;
    position: relative;
    top: 105px;
}

.line::before {
    content: " ";
    width: 1px;
    height: 100px;
    background-color: #000;
    position: absolute;
}

.workTit a {
    cursor: pointer;
    font-family: Samsung Sharp Sans Bold;
    font-size: 8em;
    color: transparent;
    -webkit-text-stroke-width: 0.02em;
    -webkit-text-stroke-color: #fff;
    margin: auto;
    opacity: 0;
    position: absolute;
}


.contTxt {
    top: 3%;
    transform: unset;
    position: absolute;
    left: 15%;
}

.txtAbout {
    width: 75%;
}

.txtAbout h2 {}

.txtAbout p {
    font-size: 3.4em;
    color: #c4c4c4;
}
.txtAbout a {
    color: #627477;
    transition: all 0.5s ease-out 0s;
}
.txtAbout a:hover {
    color: #000;
}
.txtAbout b {
    color: transparent;
    -webkit-text-stroke: 2px #848e92;
    -webkit-text-fill-color: transparent;
}

.txtContacts {
    font-size: 3.4em;
    color: #c4c4c4;
}

.txtContacts ul {
    list-style: none;
    margin: 0;
}

.txtContacts li {
    margin: 0;
    display: block;
}

.txtContacts span {
    color: transparent;
    -webkit-text-stroke: 2px #848e92;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 800;
    margin: 0.7em 0 0 0;
}
.txtContacts a {
    color: #627477;
    transition: all 0.5s ease-out 0s;
}

.txtContacts a:hover {
    color: #000;
}

.sideDx {
    width: 82%;
    height: auto;
    position: absolute;
    left: 15%;
}

.sideDx h2 {
    font-size: 5em;
}

.sideDx p {
    font-size: 2em;
    font-weight: 300;
}

.blockWork {
    display: grid;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 0;
    grid-auto-rows: min-content;
    margin: auto;
    min-height: 100vh;
    grid-template-rows: auto;
    padding: 3vw 0;
    width: auto;
    height: auto;
    /* border: 2px solid black; */
    /* background-color: yellow; */
}

.blockWork div img {
    width: 100%;
}

.imageRandom-0,
.imageRandom-1,
imageRandom-2,
imageRandom-3 {
    position: relative;
}

.imageRandom-0 {
    grid-column: 1/span 11;
    padding-bottom: 7%;
    margin-top: 8vh;
}

.imageRandom-1 {
    grid-column: 2/span 9;
    padding-bottom: 10%;
    margin-top: 5vh;
}

.imageRandom-2 {
    grid-column: 13/span 7;
    margin-top: -20vh;
    padding-bottom: 0%;
}

.imageRandom-3 {
    grid-column: 1/span 7;
    padding-bottom: 15%;
}

.workTit {
    color: white;
    mix-blend-mode: difference;
    position: fixed;
    top: calc(50% - 8em);
    width: 80vw;
    height: 12em;
    z-index: 1;
    overflow: hidden;
    opacity: 1;
}

.workTit.hide {
    pointer-events: none;
    opacity: 0;
    transition: .2s;
}

.workTit.play a {
    /* opacity: 1 !important;  */
    -moz-animation: spinVertical .2s ease-out .2s forwards;
    -o-animation: spinVertical .2s ease-out .2s forwards;
    -webkit-animation: spinVertical .2s ease-out .2s forwards;
    animation: spinVertical .2s ease-out .2s forwards;
}

@keyframes spinVertical {
    0% {
        transform: rotate(-15deg);
        transform-origin: center left;
        opacity: 0;
    }
    100% {
        transform: rotate(0);
        transform-origin: center left;
        opacity: 1;
    }
}

.workTit.stop a {
    -moz-animation: fadeOutVertical .5s ease-out forwards;
    -o-animation: fadeOutVertical .5s ease-out forwards;
    -webkit-animation: fadeOutVertical .5s ease-out forwards;
    animation: fadeOutVertical .5s ease-out forwards;
}

@keyframes fadeOutVertical {
    0% {
        transform: rotate(0deg);
        transform-origin: center left;
        opacity: 1;
    }
    100% {
        transform: rotate(25deg);
        transform-origin: center left;
        opacity: 0;
    }
}

.cta {
    font-size: 4em;
    font-weight: bold;
}

.cta-light {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: transparent;
}

.cta a:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

.cta a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

    .slider-container {
        top: calc(50vh - 4em);
        left: 0;
        z-index: 2;
        /* position: absolute; */
        width: 100vw;
        height: 8em;
        position: fixed;
        /* background-color: pink; */
    }

    .slider-2 {
        z-index: 3;
        display: flex;
    }

    .slide-track-2 {
        animation: slider-2 10s linear infinite;
        display: flex;
        flex-wrap: nowrap;
    }

    .slider-2:hover > * {
        animation-play-state: paused;
    }

    @keyframes slider-2 {
        0% {
            transform: translateZ(0);
        }
        100% {
            transform: translate3d(-100%, 0, 0);
        }
    }

    .slide-cta {
        margin: 0;
        padding: 0 1em;
        text-align: center;
        width: fit-content;
        white-space: nowrap;
        font-size: 4em;
        font-weight: bold;
        line-height: 2em;
    }

    .slide-cta:hover {
        cursor: pointer;
    }

    .slide-cta.light {
        -webkit-text-stroke: 1px black;
        -webkit-text-fill-color: transparent;
    }


.slider {
    top: calc(50vh - 4em);
    left: 0;
    z-index: 2;
    /* position: absolute; */
    width: 100vw;
    height: 8em;
    position: fixed;
    /* background-color: pink; */
}

.slider .slide-track {
    /*background-color: yellow;*/
    -webkit-animation: scroll 10s linear infinite;
    animation: scroll 10s linear infinite;
    display: flex;
    width: calc(40vw * 8);
    line-height: 8em;
    height: 8em;
}

.slider .slide-track .slide {
    width: 40vw;
    text-align: center;
    /* margin: 0 4em 4em 0; */
}

.slider .slide-track:hover {
    animation-play-state: paused;
    cursor: pointer;
}

img {
    max-width: none;
    vertical-align: unset;
}

#frames-scroll {
    width: 60vw;
    height: calc(60vw / 1.77);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
    max-height: 100vh;
}

#frames-scroll:not(.canvasZoom) {
    animation: frames-scroll-begin 0.875s forwards;
}

@keyframes frames-scroll-begin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes scroll {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0);
        opacity: 1;
    }
}

@keyframes scroll {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(calc(-40vw * 2),0,0);
        transform: translate3d(calc(-40vw * 2),0,0);
        opacity: 1;
    }
}

/* #root::before {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: transparent linear-gradient(129deg, rgba(244, 252, 255, 0.49) 0%, rgba(224, 247, 255, 0.49) 100%) 0% 0% no-repeat padding-box;
    content: '';
    opacity: 0;
} */

#root.canvasZoom::before {
    opacity: 1;
    transition: 1.2s ease-in;
    -moz-transition: 1.2s ease-in;
    -webkit-transition: 1.2s ease-in;
    -o-transition: 1.2s ease-in;
}

#frames-scroll.canvasZoom {
    animation: frames-scroll-end 1.2s forwards;
}

@keyframes frames-scroll-end {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.scrollCTAContainer {
    position: fixed;
    bottom: 3em;
    left: calc(50vw - 6em);
    text-align: center;
    width: 12em;
    /* overflow: hidden; */
    font: normal normal normal 12px/20px "Samsung Sharp Sans Bold";
    letter-spacing: 0px;
}

.scrollLine {
    height: 4.5em;
    width: 1px;
    position: relative;
    left: 50%;
    top: 0;
    opacity: 0;
    border-radius: 1em;
    background: linear-gradient(rgba(77, 77, 77, 0.5) 50%, #000 50%);
}

.scrollLine.idle {
    animation: scrollInLanding 0.85s cubic-bezier(0.35, 0, 0.3, 1) forwards, scrollUpLanding 2.2s linear /*cubic-bezier(0.76, 0, 0.3, 1)*/ forwards infinite;
    animation-delay: -0.2s, 0.2s;
    -webkit-animation: scrollInLanding 0.85s cubic-bezier(0.35, 0, 0.3, 1) forwards, scrollUpLanding 2.2s linear /*cubic-bezier(0.76, 0, 0.3, 1)*/ forwards infinite;
    -webkit-animation-delay: -0.2s, 0.2s;
}

.scrollLine.scrolled {
    animation: scrollOutLanding 1.5s cubic-bezier(0.76, 0, 0.3, 1) forwards;
    -webkit-animation: scrollOutLanding 1.5s cubic-bezier(0.76, 0, 0.3, 1) forwards;
}

@keyframes scrollInLanding {
    0% {
        transform: scaleY(0);
        opacity: 0;
    }
    75% {
        transform: scaleY(0);
        opacity: 0.75;
    }
    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes scrollOutLanding {
    0% {
        transform: scaleY(1);
        opacity: 1;
    }
    75% {
        transform: scaleY(0);
        opacity: 0.75;
    }
    100% {
        transform: scaleY(0);
        opacity: 0;
    }
}

@keyframes scrollUpLanding {
    0% {
        background-position: 0 4.5em;
    }
    /* 75% {
        background-position: 0 0;
    } */
    100% {
        background-position: 0 0em;
    }
}

.scrollCTA {
    line-height: unset;
    margin-bottom: 0;
}

.scrollCTA.idle {
    animation: slideInLandingCTA 0.75s ease-out forwards;
    animation-delay: 0.5s;
    -webkit-transform: translateY(1em);
    transform: translateY(1em);
    opacity: 0;
}

.scrollCTA.scrolled {
    animation: slideOutLandingCTA 0.75s ease-out forwards;
    animation-delay: 0.5s;
}

@keyframes slideOutLandingCTA {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(1em);
        transform: translateY(1em);
        opacity: 0;
    }
}

@keyframes slideInLandingCTA {
    0% {
        -webkit-transform: translateY(1em);
        transform: translateY(1em);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.workImage {
    position: relative;
    opacity: 0;
    animation: showUPOne 1s ease-out;
    top: 1em;
}

.workImage.inViewport {
    opacity: 1;
    top: 0;
    transition: 0.675s ease-in;
}

@keyframes showUPOne {
    from {
        top: 1em;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}

.imageRandom-0 {
    /* animation: showRandom-0 2s ease-out forwards; */
    /* -webkit-animation: showRandom-0 2s; */
}

@keyframes showRandom-0 {
    from {
        top: 3em;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}

.imageRandom-1 {
    /* animation: showRandom-1 2s ease-out forwards; */
    /* -webkit-animation: showRandom-0 2s; */
}

@keyframes showRandom-1 {
    from {
        top: 4em;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}

.imageRandom-2 {
    /* animation: showRandom-2 2s ease-out forwards; */
    /* -webkit-animation: showRandom-2 2s; */
}

@keyframes showRandom-2 {
    from {
        top: 3em;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1;
    }
}

.imageRandom-3 {}

.imageRandom-3 {}

.work-watch-film {
    width: 140px;
    margin: 1.5em auto auto;
    cursor: pointer;
    transition: all 0.5s ease-out 0s;
}

.work-watch-film span {
    float: left;
    margin: 1em 0.5em 0 0em;
}

.work-watch-film svg {
    float: left;
}

.work-watch-film:hover {
    opacity: 0.8;
}

.coming-soon-film {
    width: 140px;
    margin: 1.5em auto auto;
    cursor: pointer;
    transition: all 0.5s ease-out 0s;
}

.coming-soon-film span {
    float: center;
    margin: 1em 0.5em 0 0em;
}

.coming-soon-film:hover {
    cursor: default;
}

.contactLink:hover{
    -webkit-text-stroke: 2px #848e92 !important;
    -webkit-text-fill-color: unset !important;
}
    .footer svg {
        display: none;
    }
/* .scrollLineUpper,
.scrollLineLower {
    border-left: 1px solid;
    position: relative;
    left: 50%;
    margin-left: -0.5px;
    top: 0;
}

.scrollLineUpper {
    border-color: #4d4d4d;
    opacity: 0.2;
    height: 2em;
}

.scrollLineLower {
    border-color: black;
    height: 2.5em;
} */


/* } */


/*--------------------------------------------------------
		MEDIA QUERYS
-------------------------------------------------------------*/

/*LAPTOP 15 INCHES*/
@media only screen and (max-width: 98.1496em) {
    .work-title.longTitle{
        font-size: 6rem;
        top: 25%;
    }
}

/*MEDIUM*/

@media only screen and (max-width: 64.063em) {
    /* The only rule that matters */
    .logo h1,
    .logoOutline h1 {
        font-size: 3.5em;
    }
    .sideSx {
        margin: 1em 0em;
        position: relative;
        z-index: 100;
    }
    .logo {
        position: unset;
        margin: auto;
        text-align: center;
    }
    .lateral {
        position: unset;
        transform: unset;
        text-align: center;
        margin: 1em 0 0 0;
    }
    .lateral li {
        transform: unset;
        margin: auto 2em 0 2em;
        display: inline;
    }
    .lateral li a {
        font-size: 1em;
        color: #000;
        font-weight: 300;
        text-decoration: none;
    }
    
    .sideDx {
        width: 100%;
        height: auto;
        position: unset;
        left: 0%;
    }
    .workTit a {
        position: unset;
    }
    .workTit {
        top: 30%;
        width: 90vw;
        text-align: center;
    }
    .workTit a {
        font-size: 8em;
    }
    
    .blockWork {
        display: unset;
    }
    .imageRandom-0,
    .imageRandom-1,
    imageRandom-2,
    imageRandom-3 {
        margin: 0;
        padding: 0;
    }
    .imageRandom-3 {
        margin-bottom: 8em;
    }
    #frames-scroll {
        width: 95vw;
        height: calc(85vw / 1.3);
    }
    .sideDx {
        width: 90%;
        height: auto;
        position: unset;
        left: unset;
        margin: 7em auto;
    }
    .work-container-gallery {
        top: 9em;
        height: calc(100% - 11em);
    }
    .nostyle.work-back-button {
        display: none;
    }
    .work-back-button {
        position: relative;
        flex-grow: unset;
        margin-top: 0em;
    }
    .work-container-header {
        position: fixed;
        display: contents;
        margin: 90%;
        height: auto;
    }
    /* .work-title {
        text-align: center;
        font-size: 4em;
    } */
    .contTxt {
        top: 20%;
        transform: unset;
        position: unset;
        left: unset;
        width: 90%;
        margin: auto;
        text-align: center;
    }
    .txtContacts a {
        color: #627477;
        transition: all 0.5s ease-out 0s;
        display: block;
    }
    .slider .slide-track {
        width: calc(50vw * 4);
    }
    .slider .slide-track .slide {
        width: 50vw;
    }
    .txtAbout {
        width: auto;
    }
    .txtAbout b {
        color: transparent;
        -webkit-text-stroke: 0.04em #848e92;
        -webkit-text-fill-color: transparent;
    }
    .work-title, .work-title.longTitle {
        position: relative;
        cursor: pointer;
        flex-grow: 1;
        text-align: left;
        font-size: 5rem;
        font-weight: bold;
        -webkit-text-fill-color: blanchedalmond;
        mix-blend-mode: difference;
        color: #fff;
        text-align: center;
        -webkit-text-stroke-color: #fff;
    }
    .work-container-gallery{
        width: 90%;
    }
    .work-watch-film {
        cursor: pointer;
        width: 165px;
        margin: auto;
        height: 69px;
        padding: 0.5em 1em;
        mix-blend-mode: difference;
        color: #fff;
    }
    .work-watch-film svg g g {
        float: left;
        mix-blend-mode: difference;
        fill: #fff;
    }
    .slide-cta{
        font-size: 3em;
    }
    .videoModal {
        top: 0%;
        padding: 2em 0 0;
    }
    .work-container-footer {
        position: absolute;
        bottom: 1em;
        width: 90%;
    }
    .exit {
        text-align: center;
        color: #fff;
        margin: 1em auto;
        left: 50%;
        position: absolute;
        font-weight: 600;
        font-size: 1em;
        transition: all 0.5s ease-out 0s;
        transform: translateY(-50%);
        bottom: 5%;
    }
    #root::before{
        position: unset;
    }
    .coming-soon-film span{
        color: #fff;
    }
    @-webkit-keyframes scroll {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translate3d(calc(-50vw * 2),0,0);
            transform: translate3d(calc(-50vw * 2),0,0);
            opacity: 31;
        }
    }
    @keyframes scroll {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translate3d(calc(-50vw * 2),0,0);
            transform: translate3d(calc(-50vw * 2),0,0);
            opacity: 1;
        }
    }
}


/*SMALL*/

@media only screen and (max-width: 40.063em) {
    body {
        margin-top: -1em;
    }
    .workTit {
        top: 21%;
        width: 90%;
        text-align: center;
    }
    .sideDx {
        width: 90%;
    }
    .workTit.longTitle a {
        font-size: 3em;
    }
    .workTit a {
        font-size: 4em;
    }
    .sideDx {
        margin: 2em auto;
    }
    .work-container-gallery {
        width: 90%;
    }
    .work-container-gallery img {
        margin: 0;
    }
    .cta {
        font-size: 3em;
    }
    .videoModal {
        top: 0;
    }
    .imageRandom-3 {
        padding-bottom: 0%;
    }
    .imageRandom-2 {
        margin-top: 0;
    }
    .slider .slide-track {
        width: calc(90vw * 8);
    }
    .slider .slide-track .slide {
        width: 90vw;
    }
    .contTxt {
        top: 23%;
        left: 0;
        text-align: center;
        margin-bottom: 3em;
    }
    .txtAbout p {
        font-size: 1.9em;
        color: #c4c4c4;
    }
    .txtContacts {
        font-size: 1.9em;
        color: #c4c4c4;
        margin-bottom: 3em;
    }
    .txtContacts span {
        -webkit-text-stroke: 0.02em #848e92;
    }
    .slide-cta{
        font-size: 2.5em;
    }
    .work-title{
        font-size: 3.5em;
        margin: 1em auto auto;
    }
    
    .work-title.longTitle{
        font-size: 3em;
    }
    .exit {
        left: 40%;
        bottom: 15%;
    }
    .footer svg {
        display: block;
        margin: 2em auto;
        width: 65px;
    }
    @-webkit-keyframes enterscreen {
        0% {
            -webkit-transform: translateX(calc(20em * 2));
            transform: translateX(calc(20em * 2));
        }
        100% {
            -webkit-transform: translateX(calc(2em * 2));
            transform: translateX(calc(2em * 2));
        }
    }
    @keyframes enterscreen {
        0% {
            -webkit-transform: translateX(calc(20em * 2));
            transform: translateX(calc(20em * 2));
        }
        100% {
            -webkit-transform: translateX(calc(2em * 2));
            transform: translateX(calc(2em * 2));
        }
    }
    @-webkit-keyframes scroll {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translate3d(calc(-90vw * 2),0,0);
            transform: translate3d(calc(-90vw * 2),0,0);
            opacity: 1;
        }
    }
    @keyframes scroll {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translate3d(calc(-90vw * 2),0,0);
            transform: translate3d(calc(-90vw * 2),0,0);
            opacity: 1;
        }
    }
}